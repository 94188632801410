import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import {fromEvent} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PlatformBrowserService} from '@core/modules';
import {LOCATION} from '@core/tokens';
import {DestroySubscription} from '@helpers/destroy-subscription';

import {StubImages, StubImageType} from './interfaces/image-stub.interface';

@Directive({
  selector: '[appImageStub],[imgStub]',
  standalone: true,
})
export class ImageStubDirective extends DestroySubscription implements AfterViewInit, OnDestroy {

  private get imageSource(): string | null {
    const element = this.elRef;
    if (!element || !element.nativeElement) {
      return null;
    }
    return element.nativeElement.src;
  }

  @Input() imgStub: StubImageType = 'boat';
  @Input() observe = false;

  private observer: MutationObserver | null;

  constructor(
    private readonly elRef: ElementRef<HTMLImageElement>,
    private readonly renderer: Renderer2,
    private readonly platformBrowserService: PlatformBrowserService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(LOCATION) private readonly location: Location,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.checkAndSetImageStub();
    this.addErrorLoadListener();
    if (this.observe) {
      this.observer = this.addMutationObserver();
    }
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  private checkAndSetImageStub(): void {
    const location = this.location.origin;
    if (this.imageSource !== `${location}/null` && this.imageSource !== `${location}/`) {
      return;
    }
    const imgStub = this.imgStub;
    const stub = this.getStubImage(imgStub);
    this.updateImage(stub);
  }

  private getStubImage(type: StubImageType): StubImages {
    switch (type) {
    case 'flag': {
      return StubImages.FLAG;
    }
    case 'circle': {
      return StubImages.CIRCLE;
    }
    case 'user': {
      return StubImages.USER;
    }
    case 'page': {
      return StubImages.PAGE;
    }
    case 'inventory': {
      return StubImages.INVENTORY;
    }
    case 'notInterested': {
      return StubImages.NOT_INTERESTED;
    }
    default:
      return StubImages.BOAT;
    }
  }

  private updateImage(source: string): void {
    this.renderer.setProperty(this.elRef.nativeElement, 'src', source);
    this.cdr.detectChanges();
  }

  private addMutationObserver(): MutationObserver | null {
    if (!this.platformBrowserService.isBrowser) {
      return null;
    }
    if (!(window as any).MutationObserver) {
      return null;
    }
    const target = this.elRef.nativeElement;
    const observer = new MutationObserver(() => {
      this.checkAndSetImageStub();
    });
    observer.observe(target, {attributes: true, attributeFilter: ['src']});
    return observer;
  }

  private addErrorLoadListener(): void {
    const target = this.elRef.nativeElement;
    fromEvent(target, 'error')
      .pipe(
        takeUntil(this.destroyStream$),
      ).subscribe(() => {
        const stub = this.getStubImage(this.imgStub);
        this.updateImage(stub);
      });
  }

}
