export type StubImageType = 'boat' | 'user' | 'flag' | 'circle' | 'page' | 'inventory' | 'notInterested';

export enum StubImages {
  BOAT = 'assets/img/img_stub.svg',
  USER = 'assets/img/user_stub.svg',
  FLAG = 'assets/img/seazone-flag.png',
  CIRCLE = 'assets/img/circle.svg',
  PAGE = 'assets/img/page-stub.svg',
  SEARCH = 'assets/img/search-icon.svg',
  NOT_ALLOWED = 'assets/img/not-allowed.png',
  INVENTORY = 'assets/img/inventory.png',
  NOT_INTERESTED = 'assets/img/not-interested.png',
}
